<template>
  <v-data-table
    :headers="headers"
    :items="product_types"
    :search="search"
    :server-items-length="total"
    :options.sync="serverOptions"
    :sort-by.sync="sortBy"
    :sort-desc.sync="sortDesc"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <!-- <v-toolbar-title> -->
        <v-text-field
          v-model="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
        <!-- </v-toolbar-title> -->
        <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
        <v-spacer></v-spacer>
        <v-dialog
          v-model="dialog"
          max-width="500px"
        >
          <template v-slot:activator="{ on, attrs }">
            <!-- <div class="d-flex justify-space-between"> -->
            <!-- <v-text-field v-model="search" label="Search" single-line hide-details></v-text-field> -->
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
              @click="close"
            >
              เพิ่มประเภทสินค้า
            </v-btn>
            <!-- </div> -->
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-img
                      v-if="editedItem.media"
                      :src="editedItem.media.path"
                    ></v-img>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-file-input
                      label="รูปประเภทสินค้า"
                      accept="image/png, image/jpeg, image/bmp"
                      v-model="editedItem.file"
                    ></v-file-input>
                  </v-col>
<!--                  <v-col-->
<!--                    cols="12"-->
<!--                    sm="12"-->
<!--                    md="12">-->
<!--                    <v-checkbox-->
<!--                      value="Y"-->
<!--                      dense-->
<!--                      hide-details-->
<!--                      v-model="editedItem.show_package"-->
<!--                      label="เป็นประเภทแพกเกจ"-->
<!--                    ></v-checkbox>-->
<!--                  </v-col>-->


<!--                  <v-col-->
<!--                    cols="12"-->
<!--                    sm="12"-->
<!--                    md="12">-->
<!--                    <v-checkbox-->
<!--                      value="Y"-->
<!--                      dense-->
<!--                      hide-details-->
<!--                      v-model="editedItem.show_website"-->
<!--                      label="แสดงหน้าเว็บไซต์"-->
<!--                    ></v-checkbox>-->
<!--                  </v-col>-->

                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-text-field
                      v-model="editedItem.product_type_name"
                      label="ชื่อประเภทสินค้า"
                    ></v-text-field>
                    <small
                      class="validate-err"
                      v-for="(err,index) in v$.editedItem.product_type_name.$silentErrors"
                      v-if="v$.editedItem.product_type_name.$error">{{ index > 0 ? ', ' : '' }}
                      {{ err.$message }}</small>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-text-field
                      type="number"
                      v-model="editedItem.weight"
                      label="น้ำหนัก (กรัม)"
                    ></v-text-field>
                    <small
                      class="validate-err"
                      v-for="(err,index) in v$.editedItem.weight.$silentErrors"
                      v-if="v$.editedItem.weight.$error">{{ index > 0 ? ', ' : '' }}
                      {{ err.$message }}</small>
                  </v-col>
                  <v-col

                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-textarea
                      v-model="editedItem.details"
                      label="รายละเอียด"
                    ></v-textarea>
                  </v-col>

                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                text
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                text
                @click="save"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="dialogDelete"
          max-width="600px"
        >
          <v-card>
            <v-card-title class="text-h5 mb-5">
              Are you sure you want to delete this item?
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                outlined
                @click="closeDelete"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                @click="deleteItemConfirm"
              >
                OK
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.index="{ index }">
      {{ ((serverOptions.page - 1) * serverOptions.itemsPerPage) + index + 1 }}
    </template>
    <template v-slot:item.status="{ item }">
      <v-checkbox
        value="Y"
        dense
        hide-details
        @click="updateStatusPackage(item.package_id)"
        v-model="item.package_status">
      </v-checkbox>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        {{ icons.mdiPencil }}
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        {{ icons.mdiDelete }}
      </v-icon>
    </template>
    <template v-slot:item.promotion_free="{ item }">
      <span v-if="item.start_promotion && item.end_promotion">
        วันที่เริ่ม {{ item.start_promotion ? item.start_promotion : '-' }} <br>
        วันที่สิ้นสุด {{ item.end_promotion ? item.end_promotion : '-' }}
      </span>
      <span v-if="!item.start_promotion && !item.end_promotion">
        -
      </span>
    </template>

    <template v-slot:item.status="{ item }">
      {{ item.show_package === 'Y' ? 'ใช่' : 'ไม่ใช่' }}
    </template>

    <template v-slot:no-data>

    </template>
  </v-data-table>
</template>

<script>
import {mdiPencil, mdiDelete} from '@mdi/js'
import DatePicker from 'vue2-datepicker'
// import config from '../../../config'
import instance from '../../../axios.service'
import instance_product_type from '../../../services/productType'
import Swal from "sweetalert2";
import {useVuelidate} from '@vuelidate/core'
import {minValue, required} from '@vuelidate/validators'

export default {
  components: {DatePicker},
  setup() {
    return {
      v$: useVuelidate(),
      icons: {
        mdiPencil,
        mdiDelete,
      },
    }
  },
  data: () => ({
    serverOptions: {
      page: 1,
      itemsPerPage: 10,
    },
    total: 0,
    product_types: [],
    dialog: false,
    dialogDelete: false,
    search: '',
    sortBy: '',
    sortDesc: '',
    headers: [
      {
        text: 'ลำดับ',
        value: 'index',
        sortable: false
      },
      {text: 'ชื่อประเภทสินค้า', value: 'product_type_name'},
      {text: 'น้ำหนัก/weight(กรัม)', value: 'weight'},
      // {text: 'ประเภทแพ็กเกจ', value: 'status'},
      {text: 'แก้ไขข้อมูล', value: 'actions', sortable: false},
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      file: null,
      product_type_name: '',
      product_type_id: '',
      transport_status: '',
      show_package: 'N',
      show_website: 'N',
      details: '',
      weight: 0,
    },
    defaultItem: {
      file: null,
      product_type_name: '',
      product_type_id: '',
      transport_status: '',
      show_package: 'N',
      show_website: 'N',
      details: '',
      weight: 0,
    },
  }),
  validations() {
    return {
      editedItem: {
        product_type_name: {required},
        weight: {required, minValue: minValue(1)},
      }
    }
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'เพิ่ม ประเภทสินค้า' : 'แก้ไข ประเภทสินค้า'
    },
  },

  watch: {
    serverOptions: {
      handler() {
        this.getProductType()
      },
    },
    search: {
      handler() {
        this.getProductType()
      },
    },
    // dialog(val) {
    //   val || this.close()
    // },
    // dialogDelete(val) {
    //   val || this.closeDelete()
    // },
  },

  created() {
    // this.initialize()
  },
  mounted() {
    // this.getUser()
    this.getProductType()
  },

  methods: {
    editItem(item) {
      this.v$.$reset();
      this.editedIndex = this.product_types.indexOf(item)
      this.editedItem = {...item}
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.product_types.indexOf(item)
      this.editedItem = {...item}
      this.dialogDelete = true
    },
    deleteItemConfirm() {
      this.$store.state.isLoading = true
      instance_product_type.destroy(this.editedItem).then(res => {
        setTimeout(function () {
          Swal.fire({
            icon: 'success',
            title: res.data.message,
            showConfirmButton: false,
            timer: 1500
          })
        }, 150);
        this.getProductType()
        this.closeDelete()
      }).catch(err => {
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด !!',
          text: err.message,
        })
        this.$store.state.isLoading = false

      })
    },

    close() {
      this.v$.$reset();
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = {...this.defaultItem}
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = {...this.defaultItem}
        this.editedIndex = -1
      })
    },

    async save() {
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) return
      this.$store.state.isLoading = true

      if (this.editedIndex > -1) {
        instance_product_type.update(this.editedItem)
          .then(res => {
            setTimeout(function () {
              Swal.fire({
                icon: 'success',
                title: res.data.message,
                showConfirmButton: false,
                timer: 1500
              })
            }, 150);
            this.getProductType()
            this.close()
          }).catch(err => {
          Swal.fire({
            icon: 'error',
            title: 'เกิดข้อผิดพลาด !!',
            text: err.message,
          })
          this.$store.state.isLoading = false

        });
      } else {
        instance_product_type.store(this.editedItem)
          .then(res => {
            setTimeout(function () {
              Swal.fire({
                icon: 'success',
                title: res.data.message,
                showConfirmButton: false,
                timer: 1500
              })
            }, 150);
            this.getProductType()
            this.close()
          }).catch(err => {
          Swal.fire({
            icon: 'error',
            title: 'เกิดข้อผิดพลาด !!',
            text: err.message,
          })
          this.$store.state.isLoading = false

        });
      }
    },
    getProductType() {
      this.$store.state.isLoading = true
      var data = {
        search: this.search,
        size: this.serverOptions.itemsPerPage,
        page: this.serverOptions.page,
        status: '',
        sortBy: this.sortBy ?? "",
        sortDesc: this.sortDesc ?? ''
      }

      instance_product_type.get(data).then(res => {
        this.product_types = res.data.data.data;
        this.total = res.data.data.total
        this.serverOptions.itemsPerPage = this.serverOptions.itemsPerPage == -1 ? -1 : res.data.data.per_page
        this.serverOptions.page = res.data.data.current_page
        this.$store.state.isLoading = false
      }).catch(err => {

        this.$store.state.isLoading = false
      });
    },
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
</style>
